import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './auth';
import itemReducer from './item';
import signupReducer from './signupnew';
import topEligiblityReducer from './top_eligibility';
import bookInterpreterReducer from './book_interpreter';
import vasEligiblityReducer from './vas_eligibility';
import larcEligiblityReducer from './larc_eligibility';
import phoneEligiblityReducer from './phone_eligibility';
import vasAppointmentReducer from './vas_appointment';
import larcAppointmentReducer from './larc_appointment';
import counsellingAppointmentReducer from './counselling_appointment';
import topBookAppointmentReducer from './top_book_appointment';
import staticFormsReducer from './static_forms';
import appointmentFormsReducer from './appointment_slice';
import counsellingFormsReducer from './counselling_slice';
import triagePendingReducer from './triageWorkListSlice';
import callBackQBReducer from './call_back_questionnaire_builder';
import appointmentReBookingFormsReducer from './appointment_rebooking';
import multipleAppointmentReducer from './multipleAppointmentSlice';
import callbackReducer from './callbackSlice';

const rootReducer = combineReducers({
  authReducer,
  itemReducer,
  signupReducer,
  topEligiblityReducer,
  vasEligiblityReducer,
  larcEligiblityReducer,
  phoneEligiblityReducer,
  bookInterpreterReducer,
  vasAppointmentReducer,
  larcAppointmentReducer,
  counsellingAppointmentReducer,
  topBookAppointmentReducer,
  staticFormsReducer,
  appointmentFormsReducer,
  counsellingFormsReducer,
  triagePendingReducer,
  callBackQBReducer,
  appointmentReBookingFormsReducer,
  multipleAppointmentReducer,
  callbackReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
