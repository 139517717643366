import axios, { AxiosError, AxiosResponse } from 'axios';
import { getLocalStorage, setLocalStorage } from '../utils/localstorage';

// Skip authorization token for endpoints
const skipAuthorization = [
  'signin',
  'staff-login',
  'newuser',
  'two-step-authentication',
  'create-or-change-password',
  'twoFactorAuthenticationByEmail',
  'twoFactorAuthenticationBySms',
  'newUserverifybyemail',
  'newUserverifybysms',
  'newUserverifybyemail',
  'eligibility',
  'otpverification',
  'getAddressAutocomplete',
  'getAddressById',
];

// Create api instance with base url
const instance = axios.create({
  baseURL: `/api/${process.env.REACT_APP_API_VERSION}`,
});

// Handle Request on api
const requestInterceptor = (request: any) => {
  const path = request.url.split('/')[1];
  if (skipAuthorization.indexOf(path) === -1) {
    const userDetails: any = getLocalStorage('userDetails');
    const { accessToken, refreshToken } = JSON.parse(userDetails);
    request.headers.Authorization = 'Bearer ' + accessToken;
    request.headers['x-refresh'] = refreshToken;
  }
  return request;
};

// Handle Respose on every api
const responseInterceptor = (response: AxiosResponse) => {
  const newAccessToken: string = response.headers['x-access-token'];
  if (newAccessToken) {
    const userDetails: any = getLocalStorage('userDetails');
    const userDetail = JSON.parse(userDetails);
    setLocalStorage(
      'userDetails',
      JSON.stringify({
        ...userDetail,
        accessToken: newAccessToken,
      })
    );
  }
  return response.data;
};

// Handle Error Response
const ErrorResponseInterceptor = () => (error: AxiosError) => {
  // Check network status
  if (error.message === 'Network Error') {
    return;
  }

  if (error.response) {
    const { status, data }: any = error.response;

    if (status === 401 && data.message === 'Session Expired') {
      // Handle if the refresh token also expired and handle session expired
      localStorage.removeItem('userDetails');
      localStorage.clear();
      window.location.href = '/session-expired';
    }
  }

  if (error.response) {
    console.log(error);
    return Promise.reject(error.response.data);
  }
};

// Request Interceptor
instance.interceptors.request.use(requestInterceptor);
// Response Interceptor
instance.interceptors.response.use(
  responseInterceptor,
  ErrorResponseInterceptor()
);

export default instance;
