import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    name: '',
    userLogInEmailId: '',
    secureOtpId: '',
  },
  reducers: {
    setName: (state: any, action: PayloadAction<object>) => {
      state.name = action.payload;
    },
    setUserLogInEmailId: (state: any, action: PayloadAction<object>) => {
      state.userLogInEmailId = action.payload;
    },
    setSecureOtpId: (state: any, action: PayloadAction<object>) => {
      state.secureOtpId = action.payload;

      console.log('action.payload', action.payload);
    },
  },
});

export const { setName, setUserLogInEmailId, setSecureOtpId } =
  authSlice.actions;

export default authSlice.reducer;
