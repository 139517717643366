import React, { useEffect, useRef } from "react";
import "../style/components/Footer.css";
import Inactive from "../assets/images/Inactive.svg";
import { useLocation } from "react-router-dom";

const InactiveAlert = () => {
  const location = useLocation();
  const resendCode = location.pathname.includes("/signup/verificationcode");
  const resetPassword = location.pathname.includes("/reset/verificationcode");
  const loginResendCode = location.pathname.includes("/login/verificationcode");
  // command for need to test in staging environment
  // if (process.env.REACT_APP_DEV_MODE) {
  //   return <></>;
  // }

  const [timer, settimer] = React.useState("");
  const [inactive, setinactive] = React.useState(false);
  const warning_sec = 120;
  const warning_timeout_sec = 480000;
  const resendCodeWarning_timeout_sec = 720000;
  const timeout_sec = 600000; // 10 minutes
  const resendCode_sec = 840000; // 14 minutes
  const timeout: any = useRef({});
  const [countdown, setCountdown] = React.useState(warning_sec);

  const secondsToTime = (secs: number) => {
    const divisor_for_minutes = Number(secs) % (60 * 60);
    const minutes = Math.floor(divisor_for_minutes / 60);
    const divisor_for_seconds = divisor_for_minutes % 60;
    const seconds = Math.ceil(divisor_for_seconds);
    const obj =
      minutes > 0
        ? `${minutes} minutes ${seconds} seconds`
        : `${seconds} seconds`;
    settimer(obj);
  };

  const resetTimer = () => {
    setCountdown(warning_sec);
    clearTimeout(timeout.current.timeout1);
    clearInterval(timeout.current.timeout2);
    clearTimeout(timeout.current.timeout3);
  };

  const clearsession = () => {
    localStorage.clear();
    localStorage.removeItem("userDetails");
    window.open("https://www.bbc.com", "_blank", "noopener,noreferrer");
    window.open("https://www.google.com", "_blank", "noopener,noreferrer");
    window.open("https://www.bing.com", "_blank", "noopener,noreferrer");
    window.open("about:blank", "_self");
    window.close();
  };

  const handleLogoutTimer = () => {
    setinactive(false);
    //Add timeout duration for Resendcode flows
    const timeoutDuration =
      resetPassword || resendCode || loginResendCode
        ? resendCode_sec
        : timeout_sec;
    const warning_timeout =
      resetPassword || resendCode || loginResendCode
        ? resendCodeWarning_timeout_sec
        : warning_timeout_sec;

    timeout.current.timeout1 = setTimeout(() => {
      setinactive(true);
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);

      timeout.current.timeout2 = setInterval(() => {
        setCountdown((countdown) => countdown - 1);
      }, 1000);
    }, warning_timeout);

    timeout.current.timeout3 = setTimeout(() => {
      clearsession();
    }, timeoutDuration);
  };

  useEffect(() => {
    handleLogoutTimer();
    document.addEventListener("mousemove", () => {
      resetTimer();
      handleLogoutTimer();
    });
    document.addEventListener("keydown", () => {
      resetTimer();
      handleLogoutTimer();
    });

    return () => {
      clearTimeout(timeout.current.timeout1);
      clearInterval(timeout.current.timeout2);
      clearTimeout(timeout.current.timeout3);
      document.removeEventListener("mousemove", resetTimer);
      document.removeEventListener("keydown", resetTimer);
    };
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      secondsToTime(countdown);
    }
    if (countdown === 0) {
      clearsession();
    }
  }, [countdown]);

  return (
    <>
      {inactive && (
        <>
          <div className="alertoverlay"></div>
          <div className="alertpopup">
            <div className="alert-container">
              <div className="alertside">
                <div className="alerticon">
                  <img src={Inactive} alt="Inactive Icon" />
                </div>
                <div className="alerttext">
                  This browser window will be closed after 2 minutes of
                  inactivity - {timer} remaining
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default InactiveAlert;
