import { AxiosError } from "axios";
import { format } from "date-fns";

import { IMinicardQuestion } from "../components/questionnaires/questionnaire-builder/QuestionnarieBuilderOptions";

export function objQueryConversion(data: any) {
  return Object.keys(data)
    .filter((k) => data[k] != null)
    .map((key) => `${key}=${data[key]}`)
    .join("&");
}

export function objQueryConversionWithSearch(data: any) {
  return Object.keys(data)
    .filter((k) => data[k] != null)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");
}

export function trimText(string: any, length: number) {
  return string && string.length > length
    ? `${string
        .trim()
        .substring(0, length - 3)
        .replace(/<[^>]*>?/gm, "")}...`
    : string;
}

export function converToMin(value: number, type: "hour" | "sec") {
  if (type === "hour") {
    return value * 60;
  }
  return false;
}

export function minTimeConversion(value: number) {
  const num = value;
  const hours = num / 60;
  const rhours = Math.floor(hours);
  const minutes = (hours - rhours) * 60;
  const rminutes = Math.round(minutes);
  return { hrs: rhours, min: rminutes };
}

export function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export function trimWithLowecase(value: string) {
  const modifyValue = value.toLowerCase().trim().replace(/ /g, "");
  return modifyValue;
}

// Circle Modules access check
export function checkModulesEnables(modules: any) {
  const arrayVal: any = [];
  Object.keys(modules).map((list: any) => {
    if (list === "connect" || list === "tracker" || list === "myHealth") {
      const checkNone = modules[list].filter((x: any) => x.active === "none");
      if (modules[list].length !== checkNone.length) {
        arrayVal.push(list);
      }
    }
  });
  return arrayVal;
}

export function changeDateFmt(incomingDate: string, dateFormat: string) {
  return format(new Date(incomingDate), dateFormat);
}

// change favt status
export function changeFavStatus(
  payload: Array<IMinicardQuestion>,
  status: boolean,
  id: string | undefined
) {
  return payload.map((value) =>
    value.id === id
      ? {
          ...value,
          isFavourite: status,
        }
      : value
  );
}

// To check favt quest exist
export function checkFavQuestExist(payload: Array<IMinicardQuestion>) {
  if (payload && payload.length > 0) {
    const favt = payload.filter((fav) => fav.isFavourite);
    return favt.length > 0;
  }
}

export function checkServiceQuestExist(payload: Array<any>, service: string) {
  if (payload && payload.length > 0) {
    const favt = payload.filter((fav) => fav.service == service);
    return favt.length > 0;
  }
}

export function checkStatusQuestExist(payload: Array<any>, status: string) {
  if (payload && payload.length > 0) {
    const favt = payload.filter((fav) => fav.status == status);
    return favt.length > 0;
  }
}

// To remove duplicate array of object from array
export function removeDuplicate(key: string, payload: any) {
  return payload.filter(
    (v: any, i: any, a: any) => a.findIndex((t: any) => t[key] === v[key]) === i
  );
}

export function errorHandler(err: AxiosError) {
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
  } else if (err.request) {
    // The request was made but no response was received
    // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
  } else {
    // Something happened in setting up the request that triggered an err
  }
  throw err;
}

export function downloadPDf(base64File: any) {
  const linkSource = `data:application/pdf;base64,${base64File}`;
  const downloadLink = document.createElement("a");
  document.body.appendChild(downloadLink);
  downloadLink.href = linkSource;
  downloadLink.target = "_self";
  downloadLink.download = "sample.pdf";
  downloadLink.click();
}

export function getRandomText(len: number) {
  return Math.random().toString(36).substr(2, len);
}

export function spaceValidation(value: string) {
  return value
    .replace(/[^a-zA-Z0-9\-àç_èéù!@#$^&%*()+=-[]\/{}|:<>?,. ]/g, "")
    .replace(/(\s{2,})|[^a-zA-Z0-9\-àç_èéù!@#$^&%*()+=-[]\/{}|:<>?,.']/g, " ")
    .replace(/^\s*/, "");
}

export function charecterLimit(value: any, limit: number) {
  if (value)
    return value.length > limit ? value.substring(0, 22) + "..." : value;
}

//comman user id
export const eligibilityQuestionSetID = [
  "6b122624-49cb-4b3d-a30d-585c20652764",
  "87841b20-9ef4-4053-8ed2-ff694a3a2b3f",
  "e60ea9ab-317c-4784-9526-819f58566908",
];

export const eligibilityDOB = [
  "f09c0f7c-0c28-4af8-aeed-2e629def15dd",
  "62ed718f-6453-4479-975f-a18c6ad21891",
  "afde34f6-424a-4910-91c8-a7d5cce3640d",
];

export const counsellingQuestionSetID = [
  "9188ee83-582b-44ba-9198-e815c06d006b",
  "7d8978d5-ea7a-4af8-9b6c-6e159fb30c91",
  "0886ecbb-0783-4512-8e09-32ce4bc19465",
];

export const postLARCQuestionSetID = [
  "32a0c3ba-b282-4531-9483-708f45f60b98", // postAbortionConQuestionSetID TOP
];

export const appointmentQuestionSetID = [
  "0347033b-3b51-45d2-b10b-971ff078b72e", // appointmentQuestionSetID TOP
  "0d27dd72-c65d-46fa-800b-9ac6d3ff3c06", // appointmentQuestionSetID VAS
  "0dc41384-4439-48ce-b4bc-2df87fb6e4c0", // appointmentQuestionSetID LARC
];

export const appointmentRebookQuestionSetID = [
  "ddf450f2-541b-48fd-94a0-1f1c199ba89c", // bookingConfirmedQuestionSetID LARC
  "7aa65f12-4469-41e9-9c0b-04c296c85798", //  bookingConfirmedQuestionSetID TOP
  "d8c294da-3aa7-4f6d-bac8-c97647ea84dd", // bookingConfirmedQuestionSetID VAS
];
