import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../../../style/questionnaire/Nav.css';
import useAuthContext from '../../../context/provider/AuthContext';
import { UserTypes } from '../../../models/UserTypes';

interface SecondaryProps {}

export const SecondaryNav: React.FC<SecondaryProps> = () => {
  const {
    state: { user },
  } = useAuthContext();

  const tabIndex = 0;
  const location = useLocation();
  const AdminMenu = [] as any;
  const superAdminMenu = [] as any;

  if (
    location &&
    `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` ==
      '/admin/dashboard'
  ) {
    AdminMenu.push(
      {
        title: 'All Clients',
        link: '/admin/dashboard/all-clients',
      },
      {
        title: 'Call backs',
        link: '/admin/dashboard/callbacks/pending',
      },
      {
        title: 'Triage worklist',
        link: '/admin/dashboard/triage',
      }
    );
  }

  if (
    location &&
    `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` ==
      '/questionnaires/form'
  ) {
    AdminMenu.push(
      {
        title: 'All forms',
        link: '/questionnaires/form/all',
      },
      {
        title: 'Form approvals',
        link: '/questionnaires/form/approval',
      },
      {
        title: 'Responses',
        link: '/questionnaires/form/responses',
      }
    );
  }

  if (
    location &&
    `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` ==
      '/admin/dashboard'
  ) {
    superAdminMenu.push(
      {
        title: 'All Clients',
        link: '/admin/dashboard/all-clients',
      },
      {
        title: 'Call backs',
        link: '/admin/dashboard/callbacks/pending',
      },
      {
        title: 'Triage worklist',
        link: '/admin/dashboard/triage',
      },
      {
        title: 'MSI staff',
        link: '/admin/dashboard/msistaff',
      }
    );
  }

  if (
    location &&
    `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` ==
      '/questionnaires/form'
  ) {
    superAdminMenu.push(
      {
        title: 'All forms',
        link: '/questionnaires/form/all',
      },
      {
        title: 'Form approvals',
        link: '/questionnaires/form/approvals',
      },
      {
        title: 'Responses',
        link: '/questionnaires/form/responses',
      }
    );
  }

  if (
    location &&
    `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` ==
      '/admin/appointment'
  ) {
    superAdminMenu.push(
      {
        title: 'Waitlist',
        link: '/admin/appointment/waitlist',
      },
      {
        title: 'Clinic information',
        link: '/admin/appointment/clinic_information',
      }
    );
  }

  const navMenu =
    user?.usertype === UserTypes.admin
      ? AdminMenu
      : user?.usertype === UserTypes.superAdmin
      ? superAdminMenu
      : [];

  if (navMenu.length <= 0) return <div></div>;

  return (
    <>
      <nav className='primary-nav second-nav'>
        <ul className='nav-list'>
          {navMenu.map((item: any) => (
            <React.Fragment key={item.title}>
              <li className={`nav-item `}>
                <Link
                  tabIndex={tabIndex}
                  className={`no-underline ${
                    item.title === 'Call backs' &&
                    [
                      '/callbacks/pending',
                      '/callbacks/completed',
                      '/callbacks/overflow',
                    ].some((subPath) => location.pathname.includes(subPath))
                      ? 'active'
                      : location.pathname.includes(item.link)
                      ? 'active'
                      : ''
                  }`}
                  to={item.link}
                >
                  {item.title}
                </Link>
              </li>
            </React.Fragment>
          ))}
        </ul>
      </nav>
    </>
  );
};
