import React from "react";
import Logo from "../../../assets/images/logo.svg";
import "../../../style/components/Header.css";
import "../../../style/pages/auth.css";
import { logoutuser } from "../../../services/auth";
import { useMsal } from "@azure/msal-react";
import { getLocalStorage } from "../../../utils/localstorage";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface LogoHeaderProps {}

async function logout() {
  const data = await logoutuser();
  if (data) {
    localStorage.clear();
    localStorage.removeItem("userDetails");
    // window.location.reload();
  }
}

export const LogoHeader: React.FC<LogoHeaderProps> = () => {
  const { instance } = useMsal();
  const handleLogout = (logoutType: string) => {
    if (logoutType === "popup") {
      const userDetails: any = getLocalStorage("userDetails");
      if (
        JSON.parse(userDetails)?.usertype == "Admin" ||
        JSON.parse(userDetails)?.usertype == "Super_Admin"
      ) {
        instance.logoutPopup({
          postLogoutRedirectUri: "/external",
          mainWindowRedirectUri: "/external",
        });
        logout();
      }
    } else {
      instance.logoutPopup({
        postLogoutRedirectUri: "/auth/login",
        mainWindowRedirectUri: "/auth/login", // redirects the top level app after logout
      });
      logout();
    }
  };

  return (
    <>
      <div className="secondary-header">
        <img tabIndex={0} src={Logo} alt="msi logo" aria-label="MSI logo" />
        <button onClick={() => handleLogout("popup")}>
          <a>Logout</a>
        </button>
        <h1 className="sr-only">Logo</h1>
      </div>
    </>
  );
};
